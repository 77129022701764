<template>
  <div
    style="display: grid; grid-template-rows: auto auto 1fr"
    class="container-xs"
  >
    <AppBackHeader to="/search/payments">ADD NEW CARD</AppBackHeader>
    <AddCardForm
      ref="form"
      style="margin-top: 3rem"
      @valid="value => (isValid = value)"
      @add-card-start="onAddCardStart"
      @add-card-success="onAddCardSuccess"
      @add-card-error="onAddCardError"
      @add-card-end="onAddCardEnd"
    />
    <XButton
      type="large"
      style="margin-top: 3rem"
      :disabled="!isValid"
      @click="$refs.form.addCard()"
      >SAVE</XButton
    >
    <LoadingModalFullScreen
      ref="loadingModal"
      text="please wait"
      non-closable
    />
    <InfoModal
      ref="errorModal"
      title="Error"
      text="Something went wrong"
      cta="try again"
      @cta="$refs.errorModal.hide()"
    />
    <InfoModal
      ref="successModal"
      title="SUCCESS!"
      text="Your card has been added to your payment methods."
      cta="CLOSE"
      hide-close-button
      @cta="$refs.successModal.hide()"
      @hide="$router.push('/search/summary')"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AppBackHeader from '../../../components-rf/AppBackHeader.vue'
import AddCardForm from '../../../components-rf/forms/AddCardForm.vue'
import LoadingModalFullScreen from '../../../components-rf/modals/LoadingModalFullScreen.vue'
import InfoModal from '../../../components-rf/modals/InfoModal.vue'
import XButton from '../../../components/common/global/XButton.vue'
export default {
  name: 'TripPayments',
  data() {
    return { isValid: false }
  },
  components: {
    AppBackHeader,
    AddCardForm,
    LoadingModalFullScreen,
    InfoModal,
    XButton,
  },
  computed: {
    ...mapState('UserModule', ['paymentMethods']),
    ...mapState('SearchState', ['paymentId']),
  },
  methods: {
    ...mapActions('UserModule', ['addStripe']),
    onAddCardStart() {
      this.$refs.loadingModal.show()
    },
    onAddCardSuccess(paymentMethod) {
      this.$store.commit('SearchState/setPaymentId', paymentMethod.id)
      this.$refs.loadingModal.hide()
      this.$refs.successModal.show()
    },
    onAddCardError() {
      this.$refs.errorModal.show()
    },
    onAddCardEnd() {
      this.$refs.loadingModal.hide()
    },
  },
}
</script>

<style scoped lang="scss"></style>
